<template>
  <div class="pagePadding">
    <ul class="topNavDiv">
      <li v-show="item.isshow" v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}
      </li>
    </ul>
    <div class="btmDiv">
      <div class="formDiv">
        <!-- 营业执照信息 -->
        <AgentRegisteCerticate ref="AgentRegisteCerticate" @allID="getAllId($event)" @sendform="getform($event)" v-show="this.clickIndex == 1"></AgentRegisteCerticate>
        <!-- 经营许可证-->
        <AgentBusinesslicense ref="AgentBusinesslicense" @sendform="getform($event)" v-show="this.clickIndex == 2"></AgentBusinesslicense>
        <!-- 备案凭证 -->
        <AgentFilingCertificate ref="AgentFilingCertificate" @sendform="getform($event)" v-show="this.clickIndex == 3"></AgentFilingCertificate>
        <!-- 补充信息 -->
        <AgentDealerInfo ref="AgentDealerInfo" @sendform="getform($event)" v-show="this.clickIndex == 4"></AgentDealerInfo>
        <!-- 首营审批 -->
        <AgentProductApproval ref="AgentProductApproval" @sendform="getform($event)" v-show="this.clickIndex == 5"></AgentProductApproval>
      </div>
    </div>
  </div>
</template>

<script>
import AgentRegisteCerticate from './AgentRegisteCerticate'
import AgentBusinesslicense from './AgentBusinesslicense'
import AgentFilingCertificate from './AgentFilingCertificate'
import AgentDealerInfo from './AgentDealerInfo'
import AgentProductApproval from './AgentProductApproval'

export default {
  name: 'clientManageFrame',
  components: {
    AgentRegisteCerticate,
    AgentBusinesslicense,
    AgentFilingCertificate,
    AgentDealerInfo,
    AgentProductApproval,
  },
  mounted() {
    // 保存列表传递数值
    this.$root.eventHub.$off('sendDataMaintenance')
    this.$root.eventHub.$on('sendDataMaintenance', data_maintenance => {
      this.data_maintenance = data_maintenance
      if (this.data_maintenance == '2') {
        this.navFrist[1].isshow = true
        this.navFrist[2].isshow = false
        this.navFrist[3].isshow = true
        this.navFrist[4].isshow = true
      }
      if (this.data_maintenance == '1') {
        this.navFrist[1].isshow = false
        this.navFrist[2].isshow = true
        this.navFrist[3].isshow = true
        this.navFrist[4].isshow = true
      }
      if (this.data_maintenance == '3') {
        this.navFrist[1].isshow = true
        this.navFrist[2].isshow = true
        this.navFrist[3].isshow = true
        this.navFrist[4].isshow = true
      }
    })

    this.$root.eventHub.$off('sendDataMaintenanceBysave')
    this.$root.eventHub.$on('sendDataMaintenanceBysave', data_maintenance => {
      this.data_maintenance = data_maintenance
      if (this.data_maintenance == '2') {
        this.navFrist[1].isshow = true
        this.navFrist[2].isshow = false
        this.navFrist[3].isshow = true
        this.navFrist[4].isshow = true
      }
      if (this.data_maintenance == '1') {
        this.navFrist[1].isshow = false
        this.navFrist[2].isshow = true
        this.navFrist[3].isshow = true
        this.navFrist[4].isshow = true
      }
      if (this.data_maintenance == '3') {
        this.navFrist[1].isshow = true
        this.navFrist[2].isshow = true
        this.navFrist[3].isshow = true
        this.navFrist[4].isshow = true
      }
    })
    this.$root.eventHub.$off('pass')
    this.$root.eventHub.$on('pass', res => {
      this.CLickbtn(res)
    })
  },
  data() {
    return {
      navFrist: [
        {
          name: '营业执照信息',
          type: 1,
          isshow: true,
        },
        {
          name: '经营许可证',
          type: 2,
          isshow: false,
        },
        {
          name: '备案凭证',
          type: 3,
          isshow: false,
        },
        {
          name: '补充信息',
          type: 4,
          isshow: false,
        },
        {
          name: '首营审批',
          type: 5,
          isshow: false,
        },
      ],
      data_maintenance: '',
      clickIndex: 1,
      // 用来存储目前进行到的步骤
      currentIndex: 1,
      hospitalid: '',
      roleId: 0,
      business_allow_id: null,
      distributor_approval: null,
      distributor_id: null,
      distributor_supply: null,
    }
  },
  watch: {
    data_maintenance() {
      this.$root.eventHub.$off('sendDataMaintenance')
      this.$root.eventHub.$on('sendDataMaintenance', data_maintenance => {
        this.data_maintenance = data_maintenance
        if (this.data_maintenance == '2') {
          this.navFrist[2].isshow = false
          this.navFrist[1].isshow = true
        }
        if (this.data_maintenance == '1') {
          this.navFrist[1].isshow = false
          this.navFrist[2].isshow = true
        }
        if (this.data_maintenance == '3') {
          this.navFrist[1].isshow = true
          this.navFrist[2].isshow = true
        }
      })
    },
  },
  methods: {
    CLickbtn(data) {
      console.log(data)
      console.log(this.$refs.AgentRegisteCerticate.form)
      console.log(this.$refs.AgentBusinesslicense.form)
      console.log(this.$refs.AgentFilingCertificate.form)
      console.log(this.$refs.AgentDealerInfo.form)
      // 营业执照必填
      if (!this.$refs.AgentRegisteCerticate.form.distributor_name) {
        this.$Message.warning('请填写公司名称')
        return
      }
      if (!this.$refs.AgentRegisteCerticate.form.social_credit_code) {
        this.$Message.warning('请填写统一社会信用代码')
        return
      }
      if (!this.$refs.AgentRegisteCerticate.form.company_detailed_address) {
        this.$Message.warning('请填写公司详细地址')
        return
      }
      if (!this.$refs.AgentRegisteCerticate.form.legal_person) {
        this.$Message.warning('请填写法人')
        return
      }
      if (!this.$refs.AgentRegisteCerticate.form.register_date) {
        this.$Message.warning('请填写起登记日期')
        return
      }

      // 如果长期
      if (this.$refs.AgentRegisteCerticate.is_long_term == '1') {
        if (!this.$refs.AgentRegisteCerticate.form.business_start) {
          this.$Message.warning('请填写起始营业期限')
          return
        }
      }

      // 如果不长期
      if (this.$refs.AgentRegisteCerticate.is_long_term == '0') {
        if (!this.$refs.AgentRegisteCerticate.business_start) {
          this.$Message.warning('请填写起始营业期限')
          return
        }
        if (!this.$refs.AgentRegisteCerticate.form.business_end) {
          this.$Message.warning('请填写截止营业期限')
          return
        }
      }

      if (!this.$refs.AgentRegisteCerticate.form.business_range) {
        this.$Message.warning('请填写经营范围')
        return
      }
      if (!this.$refs.AgentRegisteCerticate.form.residence) {
        this.$Message.warning('请填写住所')
        return
      }
      if (this.$refs.AgentRegisteCerticate.form.product == '0' && this.$refs.AgentRegisteCerticate.form.fill == '0') {
        this.$Message.warning('请选择资料维护')
        return
      }
      if (this.$refs.AgentRegisteCerticate.form.product == '1') {
        // 经营许可证必填
        if (!this.$refs.AgentBusinesslicense.form.license_number) {
          this.$Message.warning('请填写许可证编号')
          return
        }
        if (!this.$refs.AgentBusinesslicense.form.distributor_name) {
          this.$Message.warning('请填写企业名称')
          return
        }
        if (!this.$refs.AgentBusinesslicense.form.bussiness_mode) {
          this.$Message.warning('请填写经营方式')
          return
        }
        if (!this.$refs.AgentBusinesslicense.form.legal_person) {
          this.$Message.warning('请填写法人')
          return
        }
        if (!this.$refs.AgentBusinesslicense.form.responsible_person) {
          this.$Message.warning('请填写企业负责人')
          return
        }
        if (!this.$refs.AgentBusinesslicense.form.residence) {
          this.$Message.warning('请填写住所')
          return
        }
        if (!this.$refs.AgentBusinesslicense.form.business_place) {
          this.$Message.warning('请填写经营场所')
          return
        }
        if (!this.$refs.AgentBusinesslicense.form.warehouse_address) {
          this.$Message.warning('请填写库房地址')
          return
        }
        if (!this.$refs.AgentBusinesslicense.form.warehouse_address) {
          this.$Message.warning('请填写库房地址')
          return
        }
        if (this.$refs.AgentBusinesslicense.form.scope_list.length == 0) {
          this.$Message.warning('请选择经营范围')
          return
        }
      }

      if (this.$refs.AgentRegisteCerticate.form.fill == '1') {
        // 备案凭证
        if (!this.$refs.AgentFilingCertificate.form.filing_number) {
          this.$Message.warning('请填写备案号')
          return
        }
        if (!this.$refs.AgentFilingCertificate.form.filing_date) {
          this.$Message.warning('请填写备案日期')
          return
        }
        if (!this.$refs.AgentFilingCertificate.form.distributor_name) {
          this.$Message.warning('请填写企业名称')
          return
        }
        if (!this.$refs.AgentFilingCertificate.form.business_mode) {
          this.$Message.warning('请填写经营方式')
          return
        }
        if (!this.$refs.AgentFilingCertificate.form.legal_person) {
          this.$Message.warning('请填写法人')
          return
        }
        if (!this.$refs.AgentFilingCertificate.form.responsible_person) {
          this.$Message.warning('请填写企业负责人')
          return
        }
        if (!this.$refs.AgentFilingCertificate.form.residence) {
          this.$Message.warning('请填写住所')
          return
        }
        if (!this.$refs.AgentFilingCertificate.form.business_place) {
          this.$Message.warning('请填写经营场所')
          return
        }
        if (!this.$refs.AgentFilingCertificate.form.warehouse_address) {
          this.$Message.warning('请填写库房地址')
          return
        }
        if (this.$refs.AgentFilingCertificate.form.scope_list.length == 0) {
          this.$Message.warning('请选择经营范围')
          return
        }
      }
      // 补充信息
      if (!this.$refs.AgentDealerInfo.form.sale_person_name) {
        this.$Message.warning('请填写姓名')
        return
      }
      if (!this.$refs.AgentDealerInfo.form.certificates_num) {
        this.$Message.warning('请填写证件号码')
        return
      }
      if (!this.$refs.AgentDealerInfo.form.phone) {
        this.$Message.warning('请填写手机号')
        return
      }
      if (!this.$refs.AgentDealerInfo.form.email) {
        this.$Message.warning('请填写邮箱')
        return
      }
      // 如果长期
      if (!this.$refs.AgentDealerInfo.longTerm) {
        if (!this.$refs.AgentDealerInfo.form.authorization_start) {
          this.$Message.warning('请填写起始营业期限')
          return
        }
      }

      // 如果不长期
      if (!this.$refs.AgentDealerInfo.longTerm) {
        if (!this.$refs.AgentDealerInfo.form.authorization_start) {
          this.$Message.warning('请填写起始营业期限')
          return
        }
        if (!this.$refs.AgentDealerInfo.form.authorization_end) {
          this.$Message.warning('请填写截止营业期限')
          return
        }
      }
      if (!this.$refs.AgentDealerInfo.form.product_line) {
        this.$Message.warning('请填产品线')
        return
      }
      if (!this.$refs.AgentDealerInfo.form.invoice_title) {
        this.$Message.warning('请填发票抬头')
        return
      }
      if (!this.$refs.AgentDealerInfo.form.duty_paragraph) {
        this.$Message.warning('请填税号')
        return
      }
      if (!this.$refs.AgentDealerInfo.form.deposit_bank) {
        this.$Message.warning('请开户银行')
        return
      }
      // 点击一键通过的时候 保存前面的信息  false 是为了屏蔽组件的保存成功弹窗
      this.$refs.AgentDealerInfo.nextstep(true)
      this.navFrist.forEach(item => {
        if (item.name == '备案凭证' && item.isshow) {
          this.$refs.AgentFilingCertificate.nextstep(true)
        }
      })
      this.navFrist.forEach(item => {
        if (item.name == '经营许可证' && item.isshow) {
          this.$refs.AgentBusinesslicense.nextstep(true)
        }
      })
      this.$refs.AgentRegisteCerticate.nextstep(true)
      console.log('8898989898')
      let distributor_id = this.$refs.AgentProductApproval.form.distributor_id
      this.$http.get(this.$api.CustomerOnePass, false).then(res => {
        // 这个赋值会覆盖原有的经销商id
        this.$refs.AgentProductApproval.form = res.data
        this.$refs.AgentProductApproval.form.admin_date = this.$moment.unix(res.data.admin_date).format('YYYY-MM-DD HH:mm:ss')
        this.$refs.AgentProductApproval.form.quality_date = this.$moment.unix(res.data.quality_date).format('YYYY-MM-DD HH:mm:ss')
        this.$refs.AgentProductApproval.form.sale_date = this.$moment.unix(res.data.sale_date).format('YYYY-MM-DD HH:mm:ss')
        this.$refs.AgentProductApproval.form.distributor_id = distributor_id || ''
        this.$http.post(this.$api.distributorManageApproval, this.$refs.AgentProductApproval.form, true).then(res => {
          this.$router.push('/clientManage')
          this.$Message.success('保存成功')
          sessionStorage.setItem('updataCache', '0')
        })
      })
    },

    getAllId(data) {
      console.log(data, 'data')
      this.$refs.AgentBusinesslicense.distributor_idByImport = data.distributor_id
      this.$refs.AgentFilingCertificate.distributor_idByImport = data.distributor_id
      this.$refs.AgentRegisteCerticate.form.distributor_id = data.distributor_id
      this.$refs.AgentDealerInfo.distributor_idByImport = data.distributor_id
      this.$refs.AgentProductApproval.distributor_idnew = data.distributor_id
      // this.distributor_supply=data.distributor_supply
      this.getDistributorInformationnew(data.distributor_id)
      this.getBusinessAllow(data.distributor_id, data.business_allow_id)
      this.Filingcertificate(data.distributor_id, data.distributor_filing)
      this.ManageSupply(data.distributor_id, data.distributor_supply)
    },

    // 获取首印审批
    getDistributorInformationnew(distributor_id) {
      let data = {
        distributor_id: distributor_id,
      }
      this.$http.get(this.$api.distributorManageDistributorInformation, data, true).then(res => {
        if (res.data.is_long_term == '1') {
          this.longTerm = true
        }
        if (res.data.is_long_term == '0') {
          this.longTerm = false
        }
        this.$refs.AgentRegisteCerticate.form = res.data
        if (res.data.data_maintenance == '3') {
          this.$refs.AgentRegisteCerticate.form.product = '1'
          this.$refs.AgentRegisteCerticate.form.fill = '1'
        }
        if (res.data.data_maintenance == '2') {
          this.$refs.AgentRegisteCerticate.form.product = '1'
          this.$refs.AgentRegisteCerticate.form.fill = '0'
        }
        if (res.data.data_maintenance == '1') {
          this.$refs.AgentRegisteCerticate.form.product = '0'
          this.$refs.AgentRegisteCerticate.form.fill = '1'
        }
        if (res.data.data_maintenance == '') {
          this.form.product = '0'
          this.form.fill = '0'
        }
        // ------处理时间
        this.$refs.AgentRegisteCerticate.form.business_end = this.$moment.unix(res.data.business_end).format('YYYY-MM-DD')
        this.$refs.AgentRegisteCerticate.form.business_start = this.$moment.unix(res.data.business_start).format('YYYY-MM-DD')
        this.$refs.AgentRegisteCerticate.form.establish_date = this.$moment.unix(res.data.establish_date).format('YYYY-MM-DD')
        this.$refs.AgentRegisteCerticate.form.register_date = this.$moment.unix(res.data.register_date).format('YYYY-MM-DD')
        // ------处理时间
        if (this.$refs.AgentRegisteCerticate.form.business_end == '1970-01-01') {
          this.$refs.AgentRegisteCerticate.form.business_end = null
        }
        if (this.$refs.AgentRegisteCerticate.form.business_start == '1970-01-01') {
          this.$refs.AgentRegisteCerticate.form.business_start = null
        }
        if (this.$refs.AgentRegisteCerticate.form.establish_date == '1970-01-01') {
          this.$refs.AgentRegisteCerticate.form.establish_date = null
        }
        if (this.$refs.AgentRegisteCerticate.form.register_date == '1970-01-01') {
          this.$refs.AgentRegisteCerticate.form.register_date = null
        }

        // 传出去 data_maintenance
        this.$root.eventHub.$emit('sendDataMaintenance', res.data.data_maintenance) // '1' '2' '3'
        this.$root.eventHub.$emit('sendDataMaintenanceToBusiness', res.data.data_maintenance)
        this.$root.eventHub.$emit('sendDataMaintenanceToFill', res.data.data_maintenance)
        if (res.data.data_maintenance == '') {
          this.$Message.error('data_maintenance错误')
        }
        // 穿出值数
        this.$root.eventHub.$emit('sendID', res.data.distributor_id)
        this.$root.eventHub.$emit('sendBusinessAllowId', res.data.business_allow_id)
        this.$root.eventHub.$emit('sendDistributorFiling', res.data.distributor_filing)
        this.$root.eventHub.$emit('sendSupply', res.data.distributor_supply)
        this.$root.eventHub.$emit('senddistributorApproval', res.data.distributor_approval)
      })
    },

    // 经营范围
    getBusinessAllow(distributor_id, business_allow_id) {
      let data = {
        distributor_id: distributor_id,
        business_allow_id: business_allow_id,
      }
      this.$http.get(this.$api.distributorManageBusinessAllow, data, true).then(res => {
        this.$refs.AgentBusinesslicense.form = res.data
        if (this.$refs.AgentBusinesslicense.form.is_long_term == '1') {
          this.$refs.AgentBusinesslicense.longTerm = true
        } else {
          this.$refs.AgentBusinesslicense.longTerm = false
        }
        this.$refs.AgentBusinesslicense.form.scope_list = res.data.bussiness_range
        if (this.$refs.AgentBusinesslicense.form.scope_list == '') {
          this.$refs.AgentBusinesslicense.form.scope_list = []
        }
        this.$refs.AgentBusinesslicense.hadSelectList = res.data.bussiness_range ? JSON.parse(JSON.stringify(res.data.bussiness_range)) : []
        this.$refs.AgentBusinesslicense.form.effective_end_date = this.$moment.unix(res.data.effective_end_date).format('YYYY-MM-DD')
        this.$refs.AgentBusinesslicense.form.effective_start_date = this.$moment.unix(res.data.effective_start_date).format('YYYY-MM-DD')
        if (this.$refs.AgentBusinesslicense.form.effective_end_date == '1970-01-01') {
          this.$refs.AgentBusinesslicense.form.effective_end_date = null
        }
        if (this.$refs.AgentBusinesslicense.form.effective_start_date == '1970-01-01') {
          this.$refs.AgentBusinesslicense.form.effective_start_date = null
        }
      })
    },

    // 获取备案凭证信息  distributorManageFilingcertificate
    Filingcertificate(distributor_id, distributor_filing) {
      let data = {
        distributor_id: distributor_id,
        distributor_filing: distributor_filing,
      }
      this.$http.get(this.$api.distributorManageFilingcertificate, data, true).then(res => {
        this.$refs.AgentFilingCertificate.form = res.data
        this.$refs.AgentFilingCertificate.form.scope_list = res.data.bussiness_range
        if (this.$refs.AgentFilingCertificate.form.scope_list == '') {
          this.$refs.AgentFilingCertificate.form.scope_list = []
        }
        if (this.$refs.AgentFilingCertificate.form.is_long_term == '1') {
          this.$refs.AgentFilingCertificate.longTerm = true
        } else {
          this.$refs.AgentFilingCertificate.longTerm = false
        }
        this.$refs.AgentFilingCertificate.form.filing_date = this.$moment.unix(res.data.filing_date).format('YYYY-MM-DD')
        if (this.$refs.AgentFilingCertificate.form.filing_date == '1970-01-01') {
          this.$refs.AgentFilingCertificate.form.filing_date = ''
        }
      })
    },

    // 补充信息
    ManageSupply(distributor_id, distributor_supply) {
      let data = {
        distributor_id: distributor_id,
        distributor_supply: distributor_supply,
      }
      if (data.distributor_supply != null) {
        this.$http.get(this.$api.distributorManageSupply, data, true).then(res => {
          this.$refs.AgentDealerInfo.form = res.data
          if (this.$refs.AgentDealerInfo.form.is_long_term == '1') {
            this.$refs.AgentDealerInfo.longTerm = true
          } else {
            this.$refs.AgentDealerInfo.form.is_long_term = '0'
            this.$refs.AgentDealerInfo.longTerm = false
          }
          this.$refs.AgentDealerInfo.form.authorization_start = this.$moment.unix(res.data.authorization_start).format('YYYY-MM-DD')
          this.$refs.AgentDealerInfo.form.authorization_end = this.$moment.unix(res.data.authorization_end).format('YYYY-MM-DD')
          if (this.$refs.AgentDealerInfo.form.authorization_start == '1970-01-01') {
            this.$refs.AgentDealerInfo.form.authorization_start = ''
          }
          if (this.$refs.AgentDealerInfo.form.authorization_end == '1970-01-01') {
            this.$refs.AgentDealerInfo.form.authorization_end = ''
          }
        })
      }
    },

    //
    clickNav(type) {
      // 如果data_maintenance为1 点击备案凭证不能跳转
      if (this.data_maintenance == '1') {
        if (type != 2) {
          this.clickIndex = type
        }
      }
      // 如果data_maintenance为2 点击经营许可证不能跳转
      if (this.data_maintenance == '2') {
        if (type != 3) {
          this.clickIndex = type
        }
      }
      // 如果data_maintenance为3 点击经营许可跟 备案凭证都能跳转
      if (this.data_maintenance == '3') {
        this.clickIndex = type
      }
      // 如果data_maintenance为'' 点击经营许可跟 备案凭证都不能跳转
      if (this.data_maintenance == '') {
        if (type != 2 && type != 3) {
          this.clickIndex = type
        }
      }
    },
    getform(data) {
      this.clickIndex = data
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  padding: 0 !important;
  background: #f0f0f0 !important;
  height: 100px;
  display: flex;
  flex-direction: column;
  .btmDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    // padding: 0px 25px 30px 25px;
    overflow-y: auto;
    .formDiv {
      flex: 1;
      margin-bottom: 30px;
      padding: 20px 25px;
    }
    .saveDiv {
      height: 36px;
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 120px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
</style>
