<template>
  <div class="content">
    <!--  基础信息 -->
    <div class="fromDiv">
      <div class="tableTitle1">
        <span class="title">销售人员授权</span>
        <!-- <Button type="primary">模板下载</Button> -->
        <!-- <Button type="primary">导入</Button> -->
      </div>
      <div class="formLine">
        <FromLabel label="授权书"></FromLabel>
        <div class="allWidth">
          <Input readonly v-model="form.authorization_name" class="iviewIptWidth307" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload label="上传" @get-key="getKey"></FormUpload>
            </span>
          </Input>
          <span class="lookupImg" @click="see">{{ form.authorization_name }}</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="姓名" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input v-model="form.sale_person_name" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel :showIcon="true" label="证件号码"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.certificates_num" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :showIcon="true" label="手机号"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input v-model="form.phone" placeholder="邮箱" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="邮箱" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.email" placeholder="邮箱" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :showIcon="true" label="授权日期"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker @on-change="changeTime($event, 1)" @on-open-change="setTimet" :options="options2" :value="form.authorization_start" format="yyyy-MM-dd" type="date" placeholder="请选择" class="iviewIptWidth170"></DatePicker>
          <span class="marginWords10"> 至 </span>
          <DatePicker @on-change="changeTime($event, 2)" :options="options" :value="form.authorization_end" :disabled="longTerm" format="yyyy-MM-dd" type="date" placeholder="请选择" class="iviewIptWidth170"></DatePicker>
          <Checkbox @on-change="LongTermChange" true-value="1" false-value="0" v-model="form.is_long_term" class="singleCheckBox">长期</Checkbox>
        </div>
        <FromLabel label="授权区域"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.authorization_region" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight">
        <div class="newformLabel fl">
          <div>
            <span class="icon">*</span>
            <span>产品线</span>
          </div>
        </div>
        <div class="allWidth">
          <i-input type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" v-model="form.product_line" maxlength="500" show-word-limit placeholder="请输入" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>

      <div class="formLine borderTopNone moreHeight">
        <div class="newformLabel fl">
          <div>
            <span>备注</span>
          </div>
        </div>
        <div class="allWidth">
          <i-input type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" v-model="form.remarks" maxlength="500" show-word-limit placeholder="请输入" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>

      <div class="tableTitle">
        <span class="title">开票信息</span>
        <!-- <Button type="primary">模板下载</Button> -->
        <!-- <Button type="primary">导入</Button> -->
      </div>

      <div class="formLine">
        <FromLabel :showIcon="true" label="发票抬头"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input v-model="form.invoice_title" placeholder="邮箱" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="税号" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.duty_paragraph" placeholder="邮箱" class="iviewIptWidth307"></i-input>
        </div>
      </div>

      <div class="formLine borderTopNone">
        <FromLabel :showIcon="true" label="开户银行"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input v-model="form.deposit_bank" placeholder="开户银行" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="行号"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.bank_num" placeholder="行号" class="iviewIptWidth307"></i-input>
        </div>
      </div>

      <div class="formLine borderTopNone">
        <FromLabel label="企业地址"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input v-model="form.business_address" placeholder="企业地址" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="企业电话"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.business_phone" placeholder="企业电话" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="saveDiv clearfix">
        <span class="pageBtn finger btnReset fl marginLeft20" @click="back">返回</span>
        <div class="pageBtn finger btnSure fr marginLeft20" @click="newNextStep">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '../../../components/formUpload'
export default {
  name: 'AgentDealerInfo',
  components: {
    FromLabel,
    FormUpload,
  },
  data() {
    return {
      license: [],
      isLoad: false,
      imgList: [{ src: '' }, { src: '' }],
      typeList: [
        { id: '1', type: '国内厂家' },
        { id: '2', type: '境外厂家' },
      ],
      //
      Businesslicense: false,
      //
      FilingCertificate: false,
      imgUrl1: '',
      // 控制是否是供应商
      issuply: false,
      imgStatus1: false,
      longTerm: false,
      form: {
        distributor_id: '', //	经营商ID
        authorization_key: '', //	经营商ID
        authorization_name: '',
        sale_person_name: '', //	经营商ID
        certificates_num: '', //	经营商ID
        phone: '', //	经营商ID
        email: '', //	经营商ID
        authorization_start: '', //	经营商ID
        authorization_end: '', //	经营商ID
        authorization_region: '', //	经营商ID
        product_line: '', //	经营商ID
        remarks: '', //	经营商ID
        invoice_title: '', //	经营商ID
        duty_paragraph: '', //	经营商ID
        deposit_bank: '', //	经营商ID
        bank_num: '', //	经营商ID
        business_address: '', //	经营商ID
        business_phone: '', //	经营商ID
        is_long_term: '0',
      },
      distributor_idByImport: '',
      options: {},
      options2: {},
    }
  },
  //
  created() {},
  mounted() {
    // 保存穿id
    this.$root.eventHub.$on('sendId', id => {
      this.form.distributor_id = id
      console.log(this.form.distributor_id, '第四个id')
    })
    this.$root.eventHub.$off('sendSupply')
    this.$root.eventHub.$on('sendSupply', distributor_supply => {
      this.form.distributor_supply = distributor_supply
      this.judge()
    })
  },
  methods: {
    newNextStep() {
      this.$parent.$refs.AgentProductApproval.gothrougthSave()
    },
    back() {
      this.$router.go(-1)
    },
    setTimet() {
      let that = this
      that.options2 = {
        disabledDate(date) {
          return date && date.valueOf() > new Date(that.$moment().format('YYYY-MM-DD'))
        },
      }
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.form.authorization_start = e
        that.form.authorization_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.form.authorization_start)
          },
        }
      } else {
        this.form.authorization_end = e
      }
    },
    // 点击长期触发实现
    LongTermChange(e) {
      this.longTerm = !this.longTerm
      this.form.authorization_end = ''
    },
    judge() {
      if (this.$route.query.id !== undefined) {
        this.ManageSupply()
      } else {
        console.log('新增进入')
      }
    },
    // 上传按钮触发事件
    getKey(key, name, url) {
      this.form.authorization_key = key
      this.form.authorization_name = name
      this.form.authorization_url = url
    },
    // 查看生产许可
    see() {
      window.open(this.form.authorization_url)
    },
    ManageSupply() {
      let data = {
        distributor_id: this.$route.query.id,
        distributor_supply: this.form.distributor_supply,
      }
      if (data.distributor_supply != null) {
        this.$http.get(this.$api.distributorManageSupply, data, true).then(res => {
          console.log(res)
          this.form = res.data
          if (this.form.is_long_term == '1') {
            this.longTerm = true
          } else {
            this.form.is_long_term = '0'
            this.longTerm = false
          }
          this.form.authorization_start = this.$moment.unix(res.data.authorization_start).format('YYYY-MM-DD')
          this.form.authorization_end = this.$moment.unix(res.data.authorization_end).format('YYYY-MM-DD')
          if (this.form.authorization_start == '1970-01-01') {
            this.form.authorization_start = ''
          }
          if (this.form.authorization_end == '1970-01-01') {
            this.form.authorization_end = ''
          }
        })
      }
    },
    ok() {},
    cancel() {},
    // 点击保存触发事件
    async nextstep(flag) {
      let status1 = false
      // -------规则校验-------
      if (this.form.sale_person_name == '') {
        this.$Message.warning('请填写补充信息页面的姓名')
        return
      }
      if (this.form.certificates_num == '') {
        this.$Message.warning('请填写补充信息页面的证件号码')
        return
      }
      if (this.form.phone == '') {
        this.$Message.warning('请填写补充信息页面的手机号')
        return
      }
      if (this.form.email == '') {
        this.$Message.warning('请填写补充信息页面的邮箱')
        return
      }
      // 如果长期
      if (this.longTerm) {
        if (this.form.authorization_start == '') {
          this.$Message.warning('请填写补充信息页面的起始营业期限')
          return
        }
      }

      // 如果不长期
      if (!this.longTerm) {
        if (this.form.authorization_start == '') {
          this.$Message.warning('请填写补充信息页面的起始营业期限')
          return
        }
        if (this.form.authorization_end == '') {
          this.$Message.warning('请填写补充信息页面的截止营业期限')
          return
        }
      }
      if (!this.form.product_line) {
        this.$Message.warning('请填补充信息页面的产品线')
        return
      }
      if (this.form.invoice_title == '') {
        this.$Message.warning('请填补充信息页面的发票抬头')
        return
      }
      if (this.form.duty_paragraph == '') {
        this.$Message.warning('请填补充信息页面的税号')
        return
      }
      if (this.form.deposit_bank == '') {
        this.$Message.warning('请填补充信息页面的开户银行')
        return
      }

      // -------规则校验-------
      if (this.form.is_long_term == null) {
        this.form.is_long_term = '0'
      }
      // 赋值给data
      let data = this.form
      data.distributor_id = this.form.distributor_id || this.$route.query.id || this.distributor_idByImport
      status1 = await this.$http
        .post(this.$api.distributorManageSupply, data, true)
        .then(res => {
          if (!flag) {
            this.$Message.success('保存成功')
          }
          // this.$emit('sendform', 5)
          sessionStorage.setItem('updataCache', '0')
          return true
        })
        .catch(err => {
          return false
        })
      return status1
    },
  },
}
</script>

<style scoped lang="less">
.moreHeight {
  height: 100px;
}

.imgBox {
  width: 200px;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
  }
}
.fontSize14px {
  font-size: 14px;
}

.iviewIptWidth1075px {
  width: 1075px;
}

.content {
  height: 100%;
  .fromDiv {
    height: 100%;
    .saveDiv {
      float: right;
      margin-top: 20px;
      margin-right: 23px;
    }
  }
}

.iviewIptWidth170 {
  width: 170px;
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle1 {
  margin: 0 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.afterWords10 {
  margin-left: 10px;
  color: #525b6d;
}
.marginWords10,
.singleCheckBox {
  color: #525b6d;
  margin: 0 10px;
}

.marginleft10px {
  // height: 100%;
  margin-left: 10px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.marginleft20px {
  // height: 100%;
  margin-left: 20px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.iviewidth300px {
  width: 300px;
}
.icon {
  font-size: 20px;
  height: 8px;
  width: 8px;
  position: absolute;
  left: 14px;
  top: 40px;
  color: #f73333;
}
.newformLabel {
  width: 187px;
  height: 100px;
  padding-left: 30px;
  background: #f8f8f9;
  border-right: 1px solid #e8eaec;
  position: relative;
  color: #525b6d;
  display: flex;
  align-items: center;
}
</style>
