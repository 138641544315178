<template>
  <div class="content">
    <!--  注册证 -->
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel label="备案凭证"></FromLabel>
        <div class="allWidth">
          <!-- 职业许可证名称  -->
          <Input v-model="form.filing_certificate_name" class="iviewIptWidth307" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload label="上传" @get-key="getKey"></FormUpload>
            </span>
          </Input>
          <span class="lookupImg nowrap" @click="seeFilingCertificate">{{ form.filing_certificate_name }}</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="备案号" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input v-model="form.filing_number" placeholder="请输入许可证编号" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="备案日期" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker
            @on-change="
              e => {
                this.form.filing_date = e
              }
            "
            @on-open-change="setTimet"
            :options="options2"
            :editable="false"
            :value="form.filing_date"
            format="yyyy-MM-dd"
            type="date"
            placeholder="请选择"
            class="iviewIptWidth307"
          ></DatePicker>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="企业名称" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input :disabled="Boolean(form.is_approved)" v-model="form.distributor_name" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="经营方式" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <RadioGroup v-model="form.business_mode">
            <Radio label="1">批发</Radio>
            <Radio label="2">零售</Radio>
            <Radio label="3">批发兼零售</Radio>
          </RadioGroup>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="法人" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input v-model="form.legal_person" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="企业负责人" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.responsible_person" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight">
        <div class="newformLabel fl">
          <div>
            <span class="icon">*</span>
            <span>住所</span>
          </div>
        </div>
        <div class="allWidth">
          <i-input maxlength="500" type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" show-word-limit v-model="form.residence" placeholder="请输入" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight">
        <div class="newformLabel fl">
          <div>
            <span class="icon">*</span>
            <span>经营场所</span>
          </div>
        </div>
        <div class="allWidth">
          <i-input maxlength="500" type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" show-word-limit v-model="form.business_place" placeholder="请输入" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight">
        <div class="newformLabel fl">
          <div>
            <span class="icon">*</span>
            <span>库房地址</span>
          </div>
        </div>
        <div class="allWidth">
          <i-input maxlength="500" type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" show-word-limit v-model="form.warehouse_address" placeholder="请输入" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="tableTitle">
        <span class="title"> 经营范围 </span>
        <span class="longTextBtn finger btnReset fr" @click="addBill">新增</span>
      </div>
      <Table :productList="listColumns" :productData="form.scope_list"></Table>
      <div class="saveDiv clearfix">
        <span class="pageBtn finger btnReset fl marginLeft20" @click="back">返回</span>
        <div class="pageBtn finger btnSure fr marginLeft20" @click="newNextStep">保存</div>
      </div>
      <!-- 新增 -->
      <Modal v-model="add" title="新增" footer-hide width="1200">
        <Form inline class="form">
          <FormItem>
            <span class="label">2017版目录：</span>
            <Select clearable filterable v-model="catalogue" class="iviewIptWidth250 marginRight10">
              <Option v-for="(item, index) in catalogueList" :value="item.scope2017" :key="index">{{ item.scope2017 }}</Option>
            </Select>
          </FormItem>
          <FormItem class="po-create-number" :label-width="10">
            <span class="pageBtn finger btnSure" @click="search">查询</span>
            <span class="pageBtn finger btnReset marginLeft20" @click="confirm">确定</span>
          </FormItem>
        </Form>
        <Table v-if="add" :productList="addListColumns" :showSizer="true" :showElevator="true" :productData="addListData" :total="addTotal" :pages="addPages" :isLoad="addIsLoad" totalText="条记录" @on-page-size-change="onPageSizeChange" @on-select="onSelect" @on-select-cancel="onSelectionCancel" @on-select-all="onSelectAll" @on-select-all-cancel="onSelectionAllCancel" @change-page="changePage" ref="customTable"></Table>
      </Modal>
    </div>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="deleteBill"></tips-component>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '../../../components/formUpload'
import Table from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
export default {
  name: 'AgentFilingCertificate',
  components: {
    FromLabel,
    FormUpload,
    Table,
    TipsComponent,
  },
  data() {
    return {
      deleteStatus: false,
      distributor_idByImport: null,
      type: '',
      license: [],
      prolong: false,
      isLoad: false,
      // 控制现实图片对话框的现实与关闭
      imgStatus1: false,
      // 控制新增对话框

      addIsLoad: false,
      add: false,
      // 对话框中浏览图片的url地址
      imgUrl1: '',
      scope_list: [],
      // 弹窗-暂存表格选中项
      selectedItem: [],
      scopeList: [],
      addListData: [],
      catalogueList: [],
      addTotal: 0,
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 68,
        },
        {
          title: '2002版医疗器械分类目录',
          key: 'scope2002',
          align: 'center',
        },
        {
          title: '2017版医疗器械分类目录',
          key: 'scope2017',
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 220,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.delectIndex = param.index
                      this.deleteStatus = true
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      addListColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 68,
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 68,
        },
        {
          title: '2017版医疗器械分类目录',
          key: 'scope2017',
          align: 'center',
        },
        {
          title: '2002版医疗器械分类目录',
          key: 'scope2002',
          align: 'center',
        },
      ],
      catalogue: '',

      total: 0,
      pages: {
        page: 1,
        rows: 10,
      },
      addPages: {
        page: 1,
        rows: 10,
      },
      // 存储下一步提交的数据
      form: {
        scope_list: [],
        distributor_id: this.$route.query.id || null, //    厂家ID
        filing_certificate_key: '', //  生产许可证key
        filing_certificate_name: '',
        filing_certificate_url: '',
        filing_number: '', //  许可证编号
        distributor_name: '', //  企业名称
        warehouse_address: '', // 库房地址
        business_place: '', //  经营场所
        residence: '', // 住所
        business_mode: '', // 经营方式 1=批发 2=零售 3=批发兼零售
        legal_person: '', //  法人
        responsible_person: '', //  企业负责人
        effective_start_date: '', //  起始有效期 格式"0000-00-00"没填不传
        effective_end_date: '', // 截止有效期 格式"0000-00-00"没填不传
        bussiness_range: '', // 经营范围
        filing_date: '',
      },
      single: false,
      // 存储省份地址
      provinceList: [],
      // 存放省份地址
      province: '',
      // 存放市区地址列表
      cityList: [],
      // 存放市区地址
      city: '',
      options2: {},
      delectIndex: 0,
    }
  },
  created() {
    this.getBelongArea()
  },
  mounted() {
    // 保存穿id
    this.$root.eventHub.$on('sendId', id => {
      this.form.distributor_id = id
    })
    // 获取穿id
    this.$root.eventHub.$off('sendID')
    this.$root.eventHub.$on('sendID', id => {
      this.form.distributor_id = id
      this.judge()
    })
    this.$root.eventHub.$off('sendDistributorFiling')
    this.$root.eventHub.$on('sendDistributorFiling', distributor_filing => {
      this.form.distributor_filing = distributor_filing
      this.judge()
    })
    this.$root.eventHub.$off('sendDataMaintenanceToFill')
    this.$root.eventHub.$on('sendDataMaintenanceToFill', data_maintenance => {
      this.data_maintenance = data_maintenance
    })
    // this.$root.eventHub.$off('sendLegalPerson')
    this.$root.eventHub.$on('sendLegalPerson', legal_person => {
      this.form.legal_person = legal_person
    })
    // this.$root.eventHub.$off('sendName')
    this.$root.eventHub.$on('sendName', name => {
      this.form.distributor_name = name
    })
    // this.$root.eventHub.$off('sendResidence')
    this.$root.eventHub.$on('sendResidence', residence => {
      this.form.residence = residence
    })
  },
  methods: {
    newNextStep() {
      this.$parent.$refs.AgentProductApproval.gothrougthSave()
    },
    back() {
      this.$router.go(-1)
    },
    setTimet() {
      let that = this
      that.options2 = {
        disabledDate(date) {
          return date && date.valueOf() > new Date(that.$moment().format('YYYY-MM-DD'))
        },
      }
    },
    seeFilingCertificate() {
      window.open(this.form.filing_certificate_url)
    },
    importTemplate() {},

    downloadTemplate() {},
    // 弹窗-跳页
    changePage(e) {
      this.addPages.page = e
      this.getAddBill()
    },
    // 弹窗-确定
    confirm() {
      // 按编号排序
      function compare(property) {
        return function (a, b) {
          let value1 = a[property]
          let value2 = b[property]
          return value1 - value2
        }
      }
      this.selectedItem.sort(compare('scope_id'))
      // 删除 _checked 键
      this.selectedItem.forEach(item => {
        delete item._checked
      })
      this.form.scope_list = this.selectedItem
      this.add = false
      this.catalogue = ''
    },
    // 弹窗-取消勾全选
    onSelectionAllCancel(data) {
      // 清空 暂存勾选项
      this.selectedItem = []
      this.isAll = false
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-勾全选
    onSelectAll(data) {
      // 清空 暂存勾选项
      this.selectedItem = []
      // 赋值 暂存勾选项
      this.selectedItem = this.addListDataAll
      // 添加勾选字段
      this.selectedItem.forEach(item => {
        item._checked = true
      })
      // 如果 暂存勾选项 的长度等于 经营范围目录 的长度则勾选表头 全选
      this.isAll = true
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-取消勾单选
    onSelectionCancel(data, row) {
      // 0表触发项的索引
      let foo = 0
      // 遍历 暂存勾选项 获取触发项的索引
      this.selectedItem.forEach((item, index) => {
        if (item.scope_id === row.scope_id) {
          foo = index
        }
      })
      // 从 暂存勾选项 中删除该触发项
      this.selectedItem.splice(foo, 1)
      // 如果 暂存勾选项 的长度等于 经营范围目录 的长度则勾选表头 全选
      this.isAll = false
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-勾单选
    onSelect(data, row) {
      this.selectedItem.push(row)
      // 如果 暂存勾选项 的长度等于 经营范围目录 的长度则勾选表头 全选
      this.isAll = this.selectedItem.length == this.addTotal
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-回显勾选项
    selectedEcho(turnPage = false) {
      // 新增供应商时此字段是null，所以需要赋[]
      if (!this.form.scope_list) {
        this.form.scope_list = []
      }
      // 需要勾选的项
      // turnPage为 true 时表正在翻页，不要连接 this.form.scope_list
      if (!turnPage) {
        this.selectedItem = [...this.form.scope_list, ...this.selectedItem]
      }
      // 去重
      // 存放唯一id作为去重的判断条件
      let obj = {}
      this.selectedItem = this.selectedItem.reduce((cur, next) => {
        // id不存即代表该项第一次出现
        if (!obj[next.scope_id]) {
          cur.push(next)
          obj[next.scope_id] = next.scope_id
        }
        // 返回去重后的勾选项
        return cur
      }, [])
      // 遍历表格当前页
      this.addListData.forEach(item1 => {
        // 遍历 暂存勾选项
        this.selectedItem.forEach(item2 => {
          // 表格当前页要勾选的项
          if (item1.scope_id === item2.scope_id) {
            item1._checked = true
          }
        })
      })
      // 如果 暂存勾选项 的长度等于 经营范围目录 的长度则勾选表头 全选
      this.isAll = this.selectedItem.length == this.addTotal
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-查询
    search() {
      this.addPages.page = 1
      let scope2017 = this.catalogue
      this.getAddBill(scope2017)
    },
    // 弹窗-改变每页显示页数
    onPageSizeChange(e) {
      this.addPages.rows = e
      this.catalogue = ''
      this.getAddBill()
    },
    // 弹窗-获取 下拉列表 数据
    getAddBillDown() {
      this.$http.get(this.$api.supplierBusinessscopeList).then(res => {
        this.catalogueList = res.data
      })
    },
    // 弹窗-获取 目录 数据
    getAddBill(scope2017 = '', turnPage = false) {
      let data = {
        scope2017: scope2017 || this.catalogue,
        page: this.addPages.page,
        rows: this.addPages.rows,
      }
      this.$http.get(this.$api.supplierBusinessscope, data).then(res => {
        this.addListData = res.data.result
        this.addTotal = res.data.total
        // 回显勾选项 turnPage为 true 表正在翻页
        this.selectedEcho(turnPage)
      })
    },
    // 弹窗-获取 目录 全部数据
    getAddBillAll() {
      this.$http.get(this.$api.supplierBusinessscope, { page: 1, rows: 10000 }).then(res => {
        this.addListDataAll = res.data.result
      })
    },
    // 弹窗-显示
    addBill() {
      this.add = true
      // 初始化页码
      this.addPages = {
        page: 1,
        rows: 10,
      }
      // 初始化搜索框
      this.catalogue = ''
      // 初始化 暂存勾选项
      this.selectedItem = []
      this.getAddBillAll()
      this.getAddBill()
      this.getAddBillDown()
    },

    // 删除某项 经营范围
    deleteBill() {
      this.deleteStatus = false
      this.form.scope_list.splice(this.delectIndex, 1)
    },
    ok() {},
    cancel() {},
    // 开始判断状态  是新增进入 还是直接进入
    judge() {
      if (this.$route.query.id !== undefined) {
        this.Filingcertificate()
      } else {
      }
    },

    // 上传按钮触发事件
    getKey(key, name, url) {
      this.form.filing_certificate_key = key
      this.form.filing_certificate_name = name
      this.form.filing_certificate_url = url
    },

    // 获取备案凭证信息  distributorManageFilingcertificate
    Filingcertificate() {
      let data = {
        distributor_id: this.$route.query.id,
        distributor_filing: this.form.distributor_filing,
      }

      if (data.distributor_filing != null) {
        this.$http.get(this.$api.distributorManageFilingcertificate, data, true).then(res => {
          this.form = res.data
          this.form.scope_list = res.data.bussiness_range
          if (this.form.scope_list == '') {
            this.form.scope_list = []
          }
          if (this.form.is_long_term == '1') {
            this.longTerm = true
          } else {
            this.longTerm = false
          }
          this.form.filing_date = this.$moment.unix(res.data.filing_date).format('YYYY-MM-DD')
          if (this.form.filing_date == '1970-01-01') {
            this.form.filing_date = ''
          }
        })
      }
    },

    // 上一步
    backstep() {
      this.$emit('sendform', 1)
    },
    // 下一步
    async nextstep(flag) {
      let status1 = false
      // ---------添加验证规则--------
      if (this.form.filing_number == '') {
        this.$Message.warning('请填写备案凭证页面的备案号')
        return
      }
      if (this.form.filing_date == '') {
        this.$Message.warning('请填写备案凭证页面的备案日期')
        return
      }
      if (this.form.distributor_name == '') {
        this.$Message.warning('请填写备案凭证页面的企业名称')
        return
      }
      if (this.form.business_mode == '') {
        this.$Message.warning('请填写备案凭证页面的经营方式')
        return
      }
      if (this.form.legal_person == '') {
        this.$Message.warning('请填写备案凭证页面的法人')
        return
      }
      if (this.form.responsible_person == '') {
        this.$Message.warning('请填写备案凭证页面的企业负责人')
        return
      }
      if (this.form.residence == '') {
        this.$Message.warning('请填写备案凭证页面的住所')
        return
      }
      if (this.form.business_place == '') {
        this.$Message.warning('请填写备案凭证页面的经营场所')
        return
      }
      if (this.form.warehouse_address == '') {
        this.$Message.warning('请填写备案凭证页面的库房地址')
        return
      }
      if (this.form.scope_list.length == 0) {
        this.$Message.warning('请选择备案凭证页面的经营范围')
        return
      }
      // ---------添加验证规则--------

      this.scope_list = this.form.scope_list
      if (this.form.is_long_term == null) {
        this.form.is_long_term = '0'
      }
      let data = {
        filing_date: this.form.filing_date,
        distributor_id: this.form.distributor_id || this.$route.query.id || this.distributor_idByImport, //    厂家ID
        filing_certificate_key: this.form.filing_certificate_key, //  生产许可证key
        filing_number: this.form.filing_number, //  许可证编号
        distributor_name: this.form.distributor_name, //  企业名称
        warehouse_address: this.form.warehouse_address, // 库房地址
        business_place: this.form.business_place, //  经营场所
        residence: this.form.residence, // 住所
        business_mode: this.form.business_mode, // 经营方式 1=批发 2=零售 3=批发兼零售
        legal_person: this.form.legal_person, //  法人
        responsible_person: this.form.responsible_person, //  企业负责人
        effective_start_date: this.form.effective_start_date, //  起始有效期 格式"0000-00-00"没填不传
        effective_end_date: this.form.effective_end_date, // 截止有效期 格式"0000-00-00"没填不传
        bussiness_range: this.scope_list, // 经营范围
        // certification_department: this.certification_department, //  发证部门
        // certification_date: this.certification_date, //    发证日期 格式"0000-00-00"没填不传
      }
      status1 = await this.$http
        .post(this.$api.distributorManageFilingcertificate, data, true)
        .then(res => {
          // 穿出id
          if (!flag) {
            this.$Message.success('保存成功')
          }
          // this.$emit('sendform', 4)
          sessionStorage.setItem('updataCache', '0')
          return true
        })
        .catch(err => {
          return false
        })
      return status1
    },
    // 获取所属区域
    getBelongArea() {
      let data = {
        parent_id: '0',
      }
      this.$http.get(this.$api.zoneList, data, true).then(res => {
        this.provinceList = res.data
        this.companyprovinceList = res.data
      })
    },
    // 选择省份后触发事件
    chooseProvince(e) {
      // this.form.zone_id = this.province + ''
      let data = {
        parent_id: this.province,
      }
      this.$http.get(this.$api.zoneList, data, true).then(res => {
        this.cityList = res.data
      })
    },
    // 选择市区触发
    chooseCity(e) {},
  },
  watch: {},
}
</script>

<style scoped lang="less">
.moreHeight {
  height: 100px;
}
.icon {
  font-size: 20px;
  height: 8px;
  width: 8px;
  position: absolute;
  left: 14px;
  top: 40px;
  color: #f73333;
}
.newformLabel {
  width: 187px;
  height: 100px;
  padding-left: 30px;
  background: #f8f8f9;
  border-right: 1px solid #e8eaec;
  position: relative;
  color: #525b6d;
  display: flex;
  align-items: center;
}
.fontSize14px {
  font-size: 14px;
}
.content {
  height: 100%;
  .fromDiv {
    height: 100%;
    .saveDiv {
      float: right;
      margin-top: 20px;
      margin-right: 23px;
    }
  }
}
.imgBox {
  width: 200px;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
  }
}
.iviewIptWidth1075px {
  width: 1075px;
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.marginRight18 {
  margin-right: 18px;
}
.tableTitle1 {
  margin: 0 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.marginWords10,
.singleCheckBox {
  font-size: 14px;
  color: #525b6d;
  margin: 0 10px;
}
</style>
