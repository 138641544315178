<template>
  <div class="content">
    <div class="topDiv">
      <a class="longTextBtn finger fl btnReset marginRight18" @click="down"> 模版下载 </a>
      <span class="fl longTextBtn finger btnReset" v-if="apiSrc">
        <FormUploadFile label="模板导入" name="xlsx_file" :multiple="false" :apiSrc="apiSrc" @get-data="getUploadData"></FormUploadFile>
      </span>
    </div>
    <!--  基础信息 -->
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel label="营业执照"></FromLabel>
        <div class="formFlIpt578 fl">
          <Input readonly v-model="form.businesslicense_name" class="iviewIptWidth307" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload label="上传" @get-key="getKey"></FormUpload>
            </span>
          </Input>
          <span class="lookupImg nowrap" @click="seeLicence">{{ form.businesslicense_name }}</span>
        </div>
        <FromLabel label="上传合同"></FromLabel>
        <div class="formFrIpt fl">
          <Input readonly v-model="form.contract_name" class="iviewIptWidth307" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload label="上传" @get-key="getKey2"></FormUpload>
            </span>
          </Input>
          <span class="lookupImg nowrap" @click="seeContract">{{ form.contract_name }}</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="公司名称" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input :disabled="Boolean(form.is_approved)" v-model="form.distributor_name" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="国外注册公司"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.foreign_registration" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="类型"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input v-model="form.type" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="统一社会信用代码" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.social_credit_code" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="公司地址" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <!-- <Select @on-change="chooseCompanyProvince" v-model="form.company_province" class="iviewIptWidth165">
            <Option v-for="(item, index) in companyprovinceList" :key="index" :value="item.area_code">{{ item.area_name }}</Option>
          </Select>
          <Select @on-change="chooseCompanyCity" v-model="form.company_city" class="iviewIptWidth165 marginLeft20">
            <Option v-for="(item, index) in companyCityList" :key="index" :value="item.area_code">{{ item.area_name }}</Option>
          </Select> -->
          <i-input v-model="form.company_detailed_address" placeholder="请输入公司详细地址" class="iviewIptWidth800x"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="法人" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input v-model="form.legal_person" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="注册资本"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.registered_capital" placeholder="请输入" class="iviewIptWidth307"></i-input>
          <span class="afterWords10"> 万元</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="成立日期"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker
            @on-change="
              e => {
                this.form.establish_date = e
              }
            "
            @on-open-change="setTimet"
            :options="options2"
            :value="form.establish_date"
            format="yyyy-MM-dd"
            type="date"
            placeholder="请选择"
            class="iviewIptWidth307"
          ></DatePicker>
        </div>
        <FromLabel label="登记日期" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker
            @on-change="
              e => {
                this.form.register_date = e
              }
            "
            @on-open-change="setTimet"
            :options="options2"
            :value="form.register_date"
            format="yyyy-MM-dd"
            type="date"
            placeholder="请选择"
            class="iviewIptWidth307"
          ></DatePicker>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :showIcon="true" label="营业期限"></FromLabel>
        <div class="allWidth">
          <DatePicker @on-change="changeTime($event, 1)" :options="options2" @on-open-change="setTimet" :value="form.business_start" format="yyyy-MM-dd" type="date" placeholder="请选择" class="iviewIptWidth170"></DatePicker>
          <span class="marginWords10"> 至 </span>
          <DatePicker @on-change="changeTime($event, 2)" :options="options" :value="form.business_end" :disabled="longTerm" format="yyyy-MM-dd" type="date" placeholder="请选择" class="iviewIptWidth170"></DatePicker>
          <Checkbox @on-change="LongTermChange" true-value="1" false-value="0" v-model="form.is_long_term" class="singleCheckBox">长期</Checkbox>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight">
        <!-- <FromLabel label="经营范围"
                   :showIcon="true"></FromLabel> -->
        <div class="newformLabel fl">
          <div>
            <span class="icon">*</span>
            <span>经营范围</span>
          </div>
        </div>

        <div class="allWidth">
          <i-input maxlength="500" type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" show-word-limit v-model="form.business_range" placeholder="请输入" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight">
        <div class="newformLabel fl">
          <div>
            <span class="icon">*</span>
            <span>住所</span>
          </div>
        </div>
        <div class="allWidth">
          <i-input maxlength="500" type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" show-word-limit v-model="form.residence" placeholder="请输入" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight">
        <div class="newformLabel fl">
          <div>
            <!-- <span class="icon">*</span> -->
            <span>备注</span>
          </div>
        </div>
        <div class="allWidth">
          <i-input maxlength="500" type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" show-word-limit v-model="form.remarks" placeholder="请输入" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="资料维护" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <Checkbox true-value="1" false-value="0" v-model="form.product" class="singleCheckBox">经营许可证</Checkbox>
          <Checkbox true-value="1" false-value="0" v-model="form.fill" class="singleCheckBox">备案凭证</Checkbox>
        </div>
      </div>
      <div class="saveDiv clearfix">
        <span class="pageBtn finger btnReset fl marginLeft20" @click="back">返回</span>
        <div class="pageBtn finger btnSure fr marginLeft20" @click="newNextStep()">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '../../../components/formUpload'
import FormUploadFile from '@/components/formUploadFile'
import http from '@/service/baseUrl'
export default {
  name: 'AgentRegisteCerticate',
  components: {
    FromLabel,
    FormUpload,
    FormUploadFile,
  },
  data() {
    return {
      diffsave: '2',
      changeList: [],
      apiSrc: '',
      options: {},
      options2: {},
      license: [],
      isLoad: false,
      // 控制 营业期限是否可选择
      longTerm: false,
      typeList: [
        { id: '1', type: '国内厂家' },
        { id: '2', type: '境外厂家' },
      ],
      // 公司省份
      companyProvince: '',
      // 公司省份列表
      companyprovinceList: [],
      // 公司市区
      companyCity: '',
      // 公司市区列表
      companyCityList: [],
      //
      Businesslicense: false,
      //
      FilingCertificate: false,
      // 控制是否是供应商
      issuply: false,
      imgStatus1: false,
      imgStatus2: false,
      data_maintenance: '',
      form: {
        distributor_name: null, // 公司名称
        businesslicense_key: null, // 营业执照key
        businesslicense_name: null,
        contract_key: null, // 合同key
        contract_name: null,
        type: null, // 	类型
        social_credit_code: null, // 统一社会信用代码
        foreign_registration: null, // 国外注册名称
        residence: null, // 	住所
        company_zone_id: null,
        // company_province: null, // 省份
        // company_city: null, // 市区
        company_detailed_address: null, // 公司详情地址
        legal_person: null, // 	法人
        registered_capital: null, //	注册资本
        establish_date: null, // 成立日期
        register_date: null, // 登记日期
        business_start: null, // 起始营业期限
        business_end: null, // 截止营业期限
        business_range: null, // 经营范围
        data_maintenance: null, // 资料维护 1=只有经营许可 2=只有备案凭证 3=经营许可和备案凭证都有
        is_long_term: '0', // 	是否长期 0=否 1=是
        remarks: null, // 备注，
        product: '0',
        fill: '0',
      },
      // 工厂id
      factory_id: '',
      // 客户id
      khid: null,
    }
  },
  //
  created() {},
  mounted() {
    // 获取数据
    // this.getBelongArea()
    // 这里获取了 厂家id
    this.judge()
    this.apiSrc = http.http + this.$api.distributorImport.url
  },
  methods: {
    newNextStep() {
      this.diffsave = window.localStorage.getItem('diffsave')
      if (this.diffsave == '1') {
        this.nextstep()
      } else {
        this.$parent.$refs.AgentProductApproval.gothrougthSave()
      }
    },
    back() {
      this.$router.go(-1)
    },
    down() {
      this.$http.downFile(this.$api.distributorTemlpateDownload, null, true).then(res => {
        let csvData = new Blob([res.data], { type: 'application/x-xlsx' }) // response.data要导出的内容
        let file_name = '经销商模版' + '.xls'
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(csvData, file_name)
        } else {
          var a = document.createElement('a')
          var url = window.URL.createObjectURL(csvData)
          a.href = url
          a.download = file_name
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        }
      })
    },
    // 模版导入
    getUploadData(data) {
      this.$Message.success('文件导入成功!')
      this.$emit('allID', data)
    },
    setTimet() {
      let that = this
      that.options2 = {
        disabledDate(date) {
          return date && date.valueOf() > new Date(that.$moment().format('YYYY-MM-DD'))
        },
      }
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.form.business_start = e
        that.form.business_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.form.business_start)
          },
        }
      } else {
        this.form.business_end = e
      }
    },
    seeLicence() {
      window.open(this.form.businesslicense_url)
    },
    seeContract() {
      window.open(this.form.contract_url)
    },
    // 点击长期触发实现
    LongTermChange(e) {
      this.longTerm = !this.longTerm
      this.form.business_end = ''
    },
    judge() {
      if (this.$route.query.id !== undefined) {
        this.getDistributorInformation()
      } else {
      }
    },
    // 上传按钮触发事件
    getKey(key, name, url) {
      this.form.businesslicense_key = key
      this.form.businesslicense_name = name
      this.form.businesslicense_url = url
    },
    getKey2(key, name, url) {
      this.form.contract_key = key
      this.form.contract_name = name
      this.form.contract_url = url
    },
    // 获取经销商营业执照信息
    getDistributorInformation() {
      let data = {
        distributor_id: this.$route.query.id,
      }
      this.$http.get(this.$api.distributorManageDistributorInformation, data, true).then(res => {
        if (res.data.is_long_term == '1') {
          this.longTerm = true
        }
        if (res.data.is_long_term == '0') {
          this.longTerm = false
        }
        this.form = res.data
        if (res.data.data_maintenance == '3') {
          this.form.product = '1'
          this.form.fill = '1'
        }
        if (res.data.data_maintenance == '2') {
          this.form.product = '1'
          this.form.fill = '0'
        }
        if (res.data.data_maintenance == '1') {
          this.form.product = '0'
          this.form.fill = '1'
        }
        if (res.data.data_maintenance == '') {
          this.form.product = '0'
          this.form.fill = '0'
        }
        // ------处理时间
        this.form.business_end = res.data.business_end ? this.$moment.unix(res.data.business_end).format('YYYY-MM-DD') : null
        this.form.business_start = res.data.business_start ? this.$moment.unix(res.data.business_start).format('YYYY-MM-DD') : null
        this.form.establish_date = res.data.establish_date ? this.$moment.unix(res.data.establish_date).format('YYYY-MM-DD') : null
        this.form.register_date = res.data.register_date ? this.$moment.unix(res.data.register_date).format('YYYY-MM-DD') : null
        // ------处理时间
        if (this.form.business_end == '1970-01-01') {
          this.form.business_end = null
        }
        if (this.form.business_start == '1970-01-01') {
          this.form.business_start = null
        }
        if (this.form.establish_date == '1970-01-01') {
          this.form.establish_date = null
        }
        if (this.form.register_date == '1970-01-01') {
          this.form.register_date = null
        }

        // 传出去 data_maintenance
        this.$root.eventHub.$emit('sendDataMaintenance', res.data.data_maintenance) // '1' '2' '3'
        this.$root.eventHub.$emit('sendDataMaintenanceToBusiness', res.data.data_maintenance)
        this.$root.eventHub.$emit('sendDataMaintenanceToFill', res.data.data_maintenance)
        if (res.data.data_maintenance == '') {
          this.$Message.error('data_maintenance错误')
        }
        // 穿出值数
        this.$root.eventHub.$emit('sendID', res.data.distributor_id)
        this.$root.eventHub.$emit('sendBusinessAllowId', res.data.business_allow_id)
        this.$root.eventHub.$emit('sendDistributorFiling', res.data.distributor_filing)
        this.$root.eventHub.$emit('sendSupply', res.data.distributor_supply)
        this.$root.eventHub.$emit('senddistributorApproval', res.data.distributor_approval)
      })
    },

    // 点击保存触发事件
    async nextstep(flag) {
      // -------规则校验-------
      let status1 = false
      if (!this.form.distributor_name) {
        this.$Message.warning('请填写营业执照信息页面的公司名称')
        return
      }
      if (!this.form.social_credit_code) {
        this.$Message.warning('请填写营业执照信息页面的统一社会信用代码')
        return
      }
      if (!this.form.company_detailed_address) {
        this.$Message.warning('请填写营业执照信息页面的公司详细地址')
        return
      }
      if (!this.form.legal_person) {
        this.$Message.warning('请填写营业执照信息页面的法人')
        return
      }
      if (!this.form.register_date) {
        this.$Message.warning('请填写营业执照信息页面的起登记日期')
        return
      }

      // 如果长期
      if (this.form.is_long_term == '1') {
        if (!this.form.business_start) {
          this.$Message.warning('请填写营业执照信息页面的起始营业期限')
          return
        }
      }

      // 如果不长期
      if (this.form.is_long_term == '0') {
        if (!this.form.business_start) {
          this.$Message.warning('请填写营业执照信息页面的起始营业期限')
          return
        }
        if (!this.form.business_end) {
          this.$Message.warning('请填写营业执照信息页面的截止营业期限')
          return
        }
      }

      if (!this.form.business_range) {
        this.$Message.warning('请填写营业执照信息页面的经营范围')
        return
      }
      if (!this.form.residence) {
        this.$Message.warning('请填写营业执照信息页面的住所')
        return
      }
      // -------规则校验-------
      if (this.form.product == '1' && this.form.fill == '1') {
        this.form.data_maintenance = '3'
        this.$root.eventHub.$emit('sendDataMaintenanceBysave', this.form.data_maintenance)
        this.$root.eventHub.$emit('sendDataMaintenanceToBusineBysave', this.form.data_maintenance)
      }
      if (this.form.product == '1' && this.form.fill == '0') {
        this.form.data_maintenance = '2'
        this.$root.eventHub.$emit('sendDataMaintenanceBysave', this.form.data_maintenance)
      }
      if (this.form.product == '0' && this.form.fill == '1') {
        this.form.data_maintenance = '1'
        this.$root.eventHub.$emit('sendDataMaintenanceBysave', this.form.data_maintenance)
      }
      if (this.form.product == '0' && this.form.fill == '0') {
        this.$Message.warning('请选择营业执照信息页面的资料维护')
        return
      }

      // -----------校验规则

      // 赋值给data
      if (this.form.is_long_term == null) {
        this.form.is_long_term = '0'
      }
      this.$root.eventHub.$on('sendId', val => {
        this.khid = val
      })
      let data = {
        distributor_id: this.$route.query.id ? this.$route.query.id : this.khid,
        distributor_name: this.form.distributor_name, // 公司名称
        businesslicense_key: this.form.businesslicense_key, // 营业执照key
        businesslicense_name: this.form.businesslicense_name,
        type: this.form.type, // 	类型
        contract_key: this.form.contract_key, // 合同key
        contract_name: this.form.contract_name,
        social_credit_code: this.form.social_credit_code, // 统一社会信用代码
        foreign_registration: this.form.foreign_registration, // 国外注册名称
        residence: this.form.residence, // 	住所
        // company_province: this.form.company_province,
        // company_city: this.form.company_city,
        company_detailed_address: this.form.company_detailed_address, // 公司详情地址
        legal_person: this.form.legal_person, // 	法人
        registered_capital: this.form.registered_capital, //	注册资本
        establish_date: this.form.establish_date, // 成立日期
        register_date: this.form.register_date, // 登记日期
        business_start: this.form.business_start, // 起始营业期限
        business_end: this.form.business_end, // 截止营业期限
        business_range: this.form.business_range, // 经营范围
        data_maintenance: this.form.data_maintenance, // 资料维护 1=只有经营许可 2=只有备案凭证 3=经营许可和备案凭证都有
        is_long_term: this.form.is_long_term, // 	是否长期 0=否 1=是
        remarks: this.form.remarks, // 备注
      }

      if (data.establish_date == null) {
        delete data.establish_date
      }
      status1 = await this.$http
        .post(this.$api.distributorManage, data, true)
        .then(res => {
          console.log(res.data.id, 'res.data.id')
          this.$root.eventHub.$emit('sendId', res.data.id)
          this.$root.eventHub.$emit('sendIDtoApproval', res.data.id)
          this.$root.eventHub.$emit('sendLegalPerson', res.data.legal_person)
          this.$root.eventHub.$emit('sendName', res.data.name)
          this.$root.eventHub.$emit('sendResidence', res.data.residence)
          if (!flag) {
            this.$Message.success('保存成功')
          }
          sessionStorage.setItem('updataCache', '0')
          if (this.form.data_maintenance == '3' || this.form.data_maintenance == '2') {
            // this.$emit('sendform', 2)
          }
          if (this.form.data_maintenance == '1') {
            // this.$emit('sendform', 3)
          }
          return true
        })
        .catch(err => {
          // console.log(err,'res.data.id')
          return false
        })
      return status1
    },
  },
}
</script>

<style scoped lang="less">
.marginRight18 {
  margin-right: 18px;
}
.moreHeight {
  height: 100px;
}
.icon {
  font-size: 20px;
  height: 8px;
  width: 8px;
  position: absolute;
  left: 14px;
  top: 40px;
  color: #f73333;
}
.newformLabel {
  width: 187px;
  height: 100px;
  padding-left: 30px;
  background: #f8f8f9;
  border-right: 1px solid #e8eaec;
  position: relative;
  color: #525b6d;
  display: flex;
  align-items: center;
}
.fontSize14px {
  font-size: 14px;
}

.iviewIptWidth1075px {
  width: 1075px;
}
.iviewIptWidth800x {
  width: 700px;
}

.content {
  height: 100%;
  .fromDiv {
    height: 100%;
    .saveDiv {
      float: right;
      margin-top: 20px;
      margin-right: 23px;
    }
  }
}

.iviewIptWidth170 {
  width: 170px;
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.afterWords10 {
  margin-left: 10px;
  color: #525b6d;
}
.marginWords10,
.singleCheckBox {
  color: #525b6d;
  margin: 0 10px;
}

.marginleft10px {
  // height: 100%;
  margin-left: 10px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.marginleft20px {
  // height: 100%;
  margin-left: 20px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.iviewidth300px {
  width: 300px;
}
</style>
